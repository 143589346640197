export const areaDisplayMethod = (data) => {
  return data.item
}

export const areaFilterByName = (item, queryText) => {
  const name = item.toLowerCase()
  const searchText = queryText.toLowerCase()

  return name.indexOf(searchText) > -1
}
