<template>
  <v-container fluid>
    <v-row justify="center" no-gutters>
      <v-card outlined>
        <v-card-text>
          <kurcc-sort-dropdown class="mb-5"/>

          <v-expansion-panels v-model="openedPanels" accordion multiple>

            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ $vuetify.lang.t('$vuetify.pages.search.sortAndFilter.filterByCategories') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <kurcc-autocomplete :items="categories" :loading="fetchingCategories"
                                    :name="$vuetify.lang.t('$vuetify.pages.search.sortAndFilter.categories')"
                                    :selected-items.sync="filter.categories" multiple outlined rules=""/>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ $vuetify.lang.t('$vuetify.pages.search.sortAndFilter.filterByBrand') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <kurcc-autocomplete :items="sites" :loading="fetchingSites"
                                    :name="$vuetify.lang.t('$vuetify.pages.search.sortAndFilter.brand')"
                                    :selected-items.sync="filter.brands" multiple outlined rules=""/>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ $vuetify.lang.t('$vuetify.pages.search.sortAndFilter.filterByArea') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <kurcc-autocomplete :display-function="areaDisplayMethod" :filter="areaFilterByName" :items="areas"
                                    :loading="fetchingAreas"
                                    :name="$vuetify.lang.t('$vuetify.pages.search.sortAndFilter.areas')"
                                    :selected-items.sync="filter.areas" multiple outlined rules=""/>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ $vuetify.lang.t('$vuetify.pages.search.sortAndFilter.filterByGrade') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <kurcc-autocomplete :items="categories" :loading="fetchingCategories"
                                    :name="$vuetify.lang.t('$vuetify.pages.search.sortAndFilter.grades')"
                                    :selected-items.sync="filter.categories" multiple outlined rules=""/>
              </v-expansion-panel-content>
            </v-expansion-panel>

          </v-expansion-panels>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn :loading="isSearching" color="primary" @click="clearFilter">
            {{ $vuetify.lang.t('$vuetify.pages.search.sortAndFilter.clearFilterBtn') }}
          </v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { RESET_FILTER, SET_FILTER } from '@/modules/app/store/mutation-types'
import { areaDisplayMethod, areaFilterByName } from '@/modules/site/helpers/utils'

export default {
  name: 'KurccSortAndFilter',
  components: {
    KurccSortDropdown: () => import('@/modules/home/components/KurccSortDropdown'),
    KurccAutocomplete: () => import('@/modules/app/components/KurccAutocomplete')
  },
  data () {
    return {
      categories: [],
      sites: [],
      areas: [],
      fetchingCategories: false,
      fetchingSites: false,
      fetchingAreas: false,
      filter: undefined,
      openedPanels: [0],
      areaDisplayMethod,
      areaFilterByName
    }
  },
  computed: {
    isSearching () {
      return this.$store.state.search.isSearching
    }
  },
  watch: {
    filter: {
      handler: function () {
        this.$store.commit(SET_FILTER, this.$lodash.cloneDeep(this.filter))
      },
      deep: true
    }
  },
  methods: {
    getAllCategories () {
      this.fetchingCategories = true
      this.$store.dispatch('getAllCategories', { withIncludes: false })
        .then(res => {
          this.categories = res.items
        })
        .finally(() => {
          this.fetchingCategories = false
        })
    },
    getAllSites () {
      this.fetchingSites = true
      this.$store.dispatch('getAllSites', {
        perPage: 999999,
        currentPage: 1,
        withIncludes: false,
        additionalParams: ['filter=id;name']
      }).then(res => {
        this.sites = res.items
      }).finally(() => {
        this.fetchingSites = false
      })
    },
    getAllConfigs () {
      this.fetchingAreas = true
      this.$store.dispatch('getAllConfigs').then(res => {
        this.areas = res.areas
      }).finally(() => {
        this.fetchingAreas = false
      })
    },
    cloneFilter () {
      this.filter = this.$lodash.cloneDeep(this.$store.state.filter)
    },
    clearFilter () {
      this.$store.commit(RESET_FILTER)
    }
  },
  created () {
    this.getAllCategories()
    this.getAllSites()
    this.getAllConfigs()
    this.cloneFilter()
  }
}
</script>
